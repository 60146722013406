import { IconButton } from '@mui/material';
import { ReactComponent as ExpandIcon } from 'images/icons/ic-expand-v2.svg';

interface OwnProps {
  drag?: any;
}

export const DragButton = ({ drag = undefined }: OwnProps) => {
  if (!drag) return null;
  return (
    <IconButton
      disableRipple
      sx={{
        padding: 0,
        marginRight: '8px',
        cursor: 'grab',
        color: (theme) => theme.palette.solidComet,
        '::before': {
          content: '""',
          position: 'absolute',
          top: '-15px',
          right: '-15px',
          bottom: '-15px',
          left: '-15px',
        },
      }}
      ref={drag}
    >
      <ExpandIcon />
    </IconButton>
  );
};

export const SimpleDragButton = (props: any) => {
  return (
    <IconButton
      {...props}
      disableRipple
      sx={{
        padding: 0,
        marginRight: '8px',
        cursor: 'grab',
        color: (theme) => theme.palette.solidComet,
        '::before': {
          content: '""',
          position: 'absolute',
          top: '-15px',
          right: '-15px',
          bottom: '-15px',
          left: '-15px',
        },
      }}
    >
      <ExpandIcon />
    </IconButton>
  );
};

import { Box, Button, Grid, Typography } from '@mui/material';
import { NavLink, useRouteError } from 'react-router-dom';
import paths from 'app/router/paths';
import { useTranslation } from 'react-i18next';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';

type Error = {
  status?: number;
};

const GenericError = ({ status }: Error) => {
  const { t } = useTranslation();
  const error = useRouteError() as Error;
  const errorCode = status ?? error?.status ?? 500;

  const getErrorMessage = () => {
    switch (errorCode) {
      case 403:
        return 'accessDeniedErrorMessage';
      case 404:
        return 'notFoundErrorMessage';
      default:
        return 'genericErrorMessage';
    }
  };

  return (
    <Box
      sx={{ minHeight: '100vh' }}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box textAlign={'center'}>
        <SentimentVeryDissatisfiedOutlinedIcon sx={{ fontSize: 150, mb: 1 }} />

        <Typography variant={'h2'} component={'h1'}>
          {t(getErrorMessage())}...
        </Typography>

        <Grid container mt={4} columnSpacing={2} justifyContent={'center'}>
          <Grid item>
            <Button variant={'outlined'} color={'secondary'} component={NavLink} to={paths.home}>
              {t('goToHomepage')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              color={'secondary'}
              component={'a'}
              href={'https://help.tablein.com/kb-tickets/new'}
              target={'_blank'}
            >
              {t('contactSupport')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GenericError;
